import BudgetContainer from "../Components/Budget/BudgetContainer";
import Notifications from "../Components/Notifications";
import Account from "../Pages/Account/Account";
import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import LogIn from "../Pages/Auth/Login/LogIn";
import UpdatePassword from "../Pages/Auth/UpdatePassword/UpdatePassword";
import BridgeTransfer from "../Pages/BridgeTransfer";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Transaction from "../Pages/Transaction/Transaction";

export const protRoutes = [
  { path: "/transaction", element: <Transaction /> },
  { path: "/account", element: <Account /> },
  { path: "/statistics", element: <Dashboard /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/budget", element: <BudgetContainer /> },
  { path: "/bridge_transfer", element: <BridgeTransfer /> },
];

export const authRoutes = [
  { path: `/login`, element: <LogIn /> },
  { path: `/forgot_password`, element: <ForgotPassword /> },
  { path: `/update_password/:token`, element: <UpdatePassword /> },
];
