import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { treasuryBalanceChart } from "../../../Data/Dashboard/DashboardChart";
import TypeTab from "../Common/TypeTab";

const TreasuryBalance = () => {
  return (
    <Col lg="6">
      <Card className="common-chart">
        <CardHeader className="pb-0 d-flex">
          <h4>Treasury Balance Over Time</h4>
          {/* <TypeTab /> */}
        </CardHeader>
        <CardBody className="pt-0">
          <ReactApexChart type="line" options={treasuryBalanceChart} series={treasuryBalanceChart.series} height={400} />
        </CardBody>
      </Card>
    </Col>
  );
};
export default TreasuryBalance;
