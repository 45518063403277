import React, { Fragment, useEffect } from "react";
import { useGetApi } from "../../Api/useGetApi";
import useNotifications from "../../Api/useNotifications";
import NotificationsTable from "./NotificationsTable";
import { readAllNotificationsApi } from "../../Api/ApiList";

const Notifications = () => {
  const { isLoading: isLoadingGetData, notificationsData, refetchGetData } = useNotifications();

  const { refetch: refetchReadAllNotificationsData } = useGetApi({ url: readAllNotificationsApi, options: { refetchOnWindowFocus: false, enabled: false } });

  useEffect(() => {
    refetchGetData()
      .then((res) => {
        const isAllNotificationRead = res?.data?.data?.filter((data) => !data?.mark_as_read);
        if (isAllNotificationRead?.length) {
          refetchReadAllNotificationsData().then(() =>
            setTimeout(() => {
              refetchGetData();
            }, 2000)
          );
        } else {
          return;
        }
      })
      .catch((err) => {
        return err;
      });
  }, []);

  return (
    <Fragment>
      <section className="common-table">
        <div className="custom-scrollbar table-responsive">
          <NotificationsTable isLoadingGetData={isLoadingGetData} notificationsData={notificationsData} refetch={refetchGetData} />
        </div>
      </section>
    </Fragment>
  );
};

export default Notifications;
