import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const BudgetTablesDropdown = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const tableTypeDropdownHead = (type) => {
    switch (type) {
      case "bank_payments":
        return "Bank Payments";
      case "credit_card_payments":
        return "Credit Card Payments";
      case "ethereum_wallet":
        return "Ethereum Wallet Payments";
      case "avail_wallet":
        return "Avail Wallet Payments";
      case "avail_difc":
        return "Avail 3rd Party Contract (DIFC)";
      case "avail_cayman":
        return "Avail 3rd Party Contract (Cayman)";
      case "avail_bvi":
        return "Avail 3rd Party Contract (BVI)";
      default:
        return "Select";
    }
  };

  const [selectedItem, setSelectedItem] = useState(tableTypeDropdownHead(type));

  const path = useLocation();
  const navigate = useNavigate();

  const handleSelect = (item, type) => {
    setSelectedItem(item);
    item !== "Select" ? navigate(`/budget?type=${type}`) : navigate(`/budget`);
  };

  useEffect(() => {
    if (path?.pathname === "/budget" && !type) {
      setSelectedItem(tableTypeDropdownHead("bank_payments"));
    }
  }, [type, path]);

  return (
    <UncontrolledDropdown className="common-dropdown">
      <DropdownToggle caret color="primary">
        {selectedItem}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleSelect("Bank Payments", "bank_payments")}>Bank Payments</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Credit Card Payments", "credit_card_payments")}>Credit Card Payments</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Ethereum Wallet Payments", "ethereum_wallet")}>Ethereum Wallet Payments</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Avail Wallet Payments", "avail_wallet")}>Avail Wallet Payments</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Avail 3rd Party Contract (DIFC)", "avail_difc")}>Avail 3rd Party Contract (DIFC)</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Avail 3rd Party Contract (Cayman)", "avail_cayman")}>Avail 3rd Party Contract (Cayman)</DropdownItem>
        <DropdownItem onClick={() => handleSelect("Avail 3rd Party Contract (BVI)", "avail_bvi")}>Avail 3rd Party Contract (BVI)</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default BudgetTablesDropdown;
