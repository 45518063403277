import React from "react";
import { Badge } from "reactstrap";
import { useBudget } from "../../../Context/BudgetContext";
import { Capitalize } from "../../../Utils";
import DynamicTable from "../../Common/CommonTable";

const CreditCardPayments = ({ data, isLoadingGetData, setEditMode, handleDelete }) => {
  const { handleSort, sortOrder } = useBudget();
  const columns = [
    {
      key: "payment_description",
      label: "Payment Description",
      isSortable: true,
    },
    {
      key: "payment_date",
      label: "Payment Date",
      type: "date",
      isSortable: true,
    },
    {
      key: "payment_source",
      label: "Payment Source",
    },
    {
      key: "currency",
      label: "Currency",
    },
    {
      key: "amount",
      label: "Amount",
      isSortable: true,
      type: "amount",
    },
    {
      key: "expense_category",
      label: "Expense Category",
    },
    {
      key: "expense_type",
      label: "Expense Type",
      isSortable: true,
    },
    {
      key: "amountUSD",
      label: "Amount (USD)",
      isSortable: true,
      type: "amount",
    },
    { 
      key: "status",
      label: "Status",
      isSortable: true,
      render: (item) => <Badge color={item?.status === "Paid" || item?.status === "Completed" ? "success" : item?.status === "Expired" || item?.status === "Cancelled" ? "danger" : "warning  "}> {Capitalize(item?.status)} </Badge>,
    },
  ];

  return <DynamicTable serialNo handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} data={data?.data || []} columns={columns} showActions={true} onDelete={handleDelete} onEdit={setEditMode} isLoading={isLoadingGetData} />;
};

export default CreditCardPayments;
