import { Formik } from "formik";
import React, { Fragment } from "react";
import { CSVLink } from "react-csv";
import { useSearchParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { importBudgetApi, uploadSignedAgreementApi } from "../../Api/ApiList";
import useImport from "../../Api/useImport";
import { useBudget } from "../../Context/BudgetContext";
import { csvData } from "../../Data/Budget";
import { handleDragOver, handleDrop } from "../../Utils";
import FileUploadBrowser from "../Common/FileUploadBrowser";

const YupObject = (schemaObject) => Yup.object().shape(schemaObject);
const requiredSchema = Yup.mixed().required();

const ImportModal = ({ fieldExtraData }) => {
  const { importModal, toggleImport, refetch, uploadId } = useBudget();
  const [searchParams] = useSearchParams();
  const paymentType = searchParams.get("type") || "bank_payments";

  const { mutate, isPending } = useImport({ url: importBudgetApi, refetch: refetch, extraFun: toggleImport });
  const { mutate: mutateAgreement } = useImport({ url: uploadSignedAgreementApi, refetch: refetch, extraFun: toggleImport });

  return (
    <Modal centered isOpen={importModal} toggle={toggleImport}>
      <ModalHeader toggle={toggleImport}>Import File</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ data: null }}
          validationSchema={YupObject({ data: requiredSchema })}
          onSubmit={async (values, { resetForm }) => {
            const extraData = { type: paymentType };
            const extraAgreementData = { type: paymentType, id: uploadId };

            if (uploadId) {
              mutateAgreement({
                url: uploadSignedAgreementApi,
                data: values?.data,
                extraData: extraAgreementData,
              });
            } else {
              mutate({
                url: importBudgetApi,
                data: values?.data,
                extraData: extraData,
              });
            }

            resetForm();
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="custom-scroll">
                <div
                  onDrop={(e) => handleDrop(e, setFieldValue)} // Bind handleDrop to setFieldValue
                  onDragOver={handleDragOver} // Proper dragover event
                  className="content-section"
                  style={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    zIndex: "99999",
                  }} // Styling for drag-and-drop area
                >
                  <div className="import-content">
                    <div>
                      <h5>
                        {!values["data"] && (
                          <Fragment>
                            Drop (ctrl + Drop) files here, paste
                            <p>or</p>
                          </Fragment>
                        )}
                        <FileUploadBrowser errors={errors} name="data" type="file" multiple={false} values={values} setFieldValue={setFieldValue} accept={uploadId ? "application/pdf" : ".csv"} />
                      </h5>
                    </div>
                  </div>
                </div>
                {!values["data"] && !uploadId && (
                  <p>
                    * Please download Example CSV
                    <CSVLink className="import-file" filename={`${paymentType}.csv`} data={csvData?.[paymentType]}>
                      Here
                    </CSVLink>
                  </p>
                )}
              </div>
              <div className="modal-footer p-0 d-flex gap-2">
                {values.data && (
                  <Button color="light" className="border clear-btn border-1 px-3" onClick={() => setFieldValue("data", null)}>
                    Clear
                  </Button>
                )}
                <div className="btn-loader loader-btn">
                  <Button type="submit" color="primary" disabled={isPending ? true : false} className="px-3 spinning-loader">
                    Submit {isPending && <div className="loader"></div>}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ImportModal;
