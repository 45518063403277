import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import { useBudget } from "../../../Context/BudgetContext";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const CreditCardPaymentsModal = ({ refetchBudget, isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );
  const [loading, setLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const { refetchGetData } = useNotifications();

  useOutsideClick(datePickerRef, () => setDatePickerVisible(false));

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "credit_card_payments",
      id: null,
      payment_description: null,
      payment_date: null,
      payment_source: null,
      currency: null,
      amount: 0,
      expense_category: null,
      expense_type: null,
      amountUSD: 0,
      status: null,
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    // const apiEndpoint = isEditMode ? updateBudgetApi : createBudgetApi;
    await mutate({ url: createOrUpdateBudgetApi, data: data });
    setLoading(false);
  };

  const handleDateChange = (date) => {
    setValue("payment_date", date, { v: true });
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("payment_description", editBudgetData.payment_description);
      setValue("payment_date", editBudgetData.payment_date ? new Date(editBudgetData.payment_date) : null);
      setValue("payment_source", editBudgetData.payment_source);
      setValue("currency", editBudgetData.currency);
      setValue("amount", editBudgetData.amount);
      setValue("expense_category", editBudgetData.expense_category);
      setValue("expense_type", editBudgetData.expense_type);
      setValue("amountUSD", editBudgetData.amountUSD);
      setValue("status", editBudgetData.status);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Credit Card Payment" : "Create Credit Card Payment"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* Payment Description */}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Payment Description:</label>
                <input {...register("payment_description", { required: "Payment description is required" })} className={`form-control ${errors.payment_description ? "is-invalid" : ""}`} type="text" placeholder="Enter payment description" />
                {errors.payment_description && <div className="invalid-feedback">{errors.payment_description.message}</div>}
              </FormGroup>
            </Col>
            {/* Payment Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Payment Date:</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker selected={watch("payment_date")} className="form-control" placeholderText="Select date" onChange={handleDateChange} open={datePickerVisible} showYearDropdown />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
              </FormGroup>
              {errors.payment_date && <div className="invalid-feedback">{errors.payment_date.message}</div>}
            </Col>
            {/* Payment Source */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Payment Source:</label>
                <input {...register("payment_source")} className={`form-control ${errors.payment_source ? "is-invalid" : ""}`} type="text" placeholder="Enter payment source" />
                {/* {errors.payment_source && <div className="invalid-feedback">{errors.payment_source.message}</div>} */}
              </FormGroup>
            </Col>
            {/* Currency */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Currency:</label>
                <input {...register("currency", { required: "Currency is required" })} className={`form-control ${errors.currency ? "is-invalid" : ""}`} type="text" placeholder="Enter currency" />
                {errors.currency && <div className="invalid-feedback">{errors.currency.message}</div>}
              </FormGroup>
            </Col>
            {/* Expense Category */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Expense Category:</label>
                <input {...register("expense_category")} className={`form-control ${errors.expense_category ? "is-invalid" : ""}`} type="text" placeholder="Enter expense category" />
                {/* {errors.expense_category && <div className="invalid-feedback">{errors.expense_category.message}</div>} */}
              </FormGroup>
            </Col>
            {/* Amount in USD */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Amount (in USD):</label>
                <input {...register("amountUSD")} className={`form-control ${errors.amountUSD ? "is-invalid" : ""}`} type="number" placeholder="Enter amount in USD" />
                {/* {errors.amountUSD && <div className="invalid-feedback">{errors.amountUSD.message}</div>} */}
              </FormGroup>
            </Col>
            {/* Type of Expense */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Type of Expense:</label>
                <select {...register("expense_type")} className={`form-control ${errors.expense_type ? "is-invalid" : ""}`}>
                  <option value="">Select an option</option>
                  <option value="Recurring">Recurring</option>
                  <option value="One-time payment">One-time payment</option>
                </select>
                {/* {errors.expense_type && <div className="invalid-feedback">{errors.expense_type.message}</div>} */}
              </FormGroup>
            </Col>
            {/* Status */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreditCardPaymentsModal;
