import React from "react";
import CommonTable from "../Common/CommonTable";

const BridgeTransferDataTable = ({ isLoadingGetData, transactionData }) => {
  const columns = [
    { key: "hash", label: "Hash", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/tx/" },
    { key: "from_account", label: "From Account", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/address/" },
    { key: "to_account", label: "To Account", type: "address", truncate: true, redirectLinkPrefix: "https://avail.subscan.io/account/" },
    { key: "amount", label: "Amount", type: "amount" },
    { key: "timestamp", label: "Timestamp", type: "date" },
  ];

  return <CommonTable columns={columns} data={transactionData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table" />;
};

export default BridgeTransferDataTable;
