import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";

const AdvanceFilter = ({ advancedFilter, setAdvancedFilter, setParams, params, refreshData, refreshedData, fieldNotToInclude }) => {
  const [startDatePickerVisible, setStartDatePickerVisible] = useState(false);
  const [endDatePickerVisible, setEndDatePickerVisible] = useState(false);
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const clearAdvancedFilter = () => {
    setAdvancedFilter({ startDate: null, endDate: null, minAmount: null, maxAmount: null, fromAddress: null, toAddress: null });
    if (advancedFilter.startDate && advancedFilter.endDate && advancedFilter.minAmount && advancedFilter.maxAmount && advancedFilter.fromAddress && advancedFilter.toAddress) setParams({ ...params, startDate: null, endDate: null, minAmount: null, maxAmount: null, from_address: null, to_address: null });
  };

  const handleMinAmountChange = (e) => {
    const value = e.target.value ? Math.max(0, Number(e.target.value)) : null;
    setAdvancedFilter((prev) => {
      const updatedMinAmount = value;
      const updatedMaxAmount = prev.maxAmount !== null && prev.maxAmount < updatedMinAmount + 1 ? updatedMinAmount + 1 : prev.maxAmount;

      return {
        ...prev,
        minAmount: updatedMinAmount,
        maxAmount: updatedMaxAmount,
      };
    });
  };

  const handleMaxAmountChange = (e) => {
    const value = e.target.value ? Math.max(0, Number(e.target.value)) : null;
    setAdvancedFilter((prev) => {
      return {
        ...prev,
        maxAmount: value,
      };
    });
  };

  const toggleStartDatePicker = () => {
    setStartDatePickerVisible((prev) => !prev);
  };

  const toggleEndDatePicker = () => {
    setEndDatePickerVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (startDatePickerRef.current && !startDatePickerRef.current.contains(event.target)) {
      setStartDatePickerVisible(false);
    }
    if (endDatePickerRef.current && !endDatePickerRef.current.contains(event.target)) {
      setEndDatePickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const shouldRenderField = (field) => !fieldNotToInclude?.includes(field);

  return (
    <Card>
      <CardBody className="list-product-body">
        <Row className="row-cols-7 g-3">
          {shouldRenderField("startDate") && (
            <Col>
              <div className="calander-icon" ref={startDatePickerRef}>
                <DatePicker
                  maxDate={new Date()}
                  className="form-control"
                  placeholderText="Start date"
                  selected={advancedFilter.startDate}
                  onChange={(date) => {
                    setAdvancedFilter({ ...advancedFilter, startDate: date });
                    setStartDatePickerVisible(false);
                  }}
                  open={startDatePickerVisible}
                />
                <i className="fa-solid fa-calendar-days" onClick={toggleStartDatePicker} />
              </div>
            </Col>
          )}

          {shouldRenderField("endDate") && (
            <Col>
              <div className="calander-icon" ref={endDatePickerRef}>
                <DatePicker
                  className="form-control"
                  placeholderText="End date"
                  selected={advancedFilter.endDate}
                  onChange={(date) => {
                    setAdvancedFilter({ ...advancedFilter, endDate: date });
                    setEndDatePickerVisible(false);
                  }}
                  open={endDatePickerVisible}
                  minDate={advancedFilter.startDate ? advancedFilter.startDate : ""}
                  maxDate={new Date()}
                />
                <i className="fa-solid fa-calendar-days" onClick={toggleEndDatePicker} />
              </div>
            </Col>
          )}

          {shouldRenderField("minAmount") && (
            <Col>
              <Input
                placeholder="Min amount"
                type="number"
                value={advancedFilter.minAmount || ""}
                onChange={handleMinAmountChange}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Col>
          )}

          {shouldRenderField("maxAmount") && (
            <Col>
              <Input
                pattern="[0-9]*"
                placeholder="Max amount"
                type="number"
                value={advancedFilter.maxAmount || ""}
                onChange={handleMaxAmountChange}
                min={advancedFilter.minAmount + 1}
                onKeyDown={(e) => {
                  // Prevent 'e', 'E', '+', '-', and other invalid characters
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  // Ensure only numbers and decimal points remain in the input
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Col>
          )}

          {shouldRenderField("fromAddress") && (
            <Col className="col-3">
              <Input placeholder="From address" type="text" value={advancedFilter.fromAddress || ""} onChange={(e) => setAdvancedFilter({ ...advancedFilter, fromAddress: e.target.value?.trim() })} />
            </Col>
          )}

          {shouldRenderField("toAddress") && (
            <Col className="col-3">
              <Input placeholder="To address" type="text" value={advancedFilter.toAddress || ""} onChange={(e) => setAdvancedFilter({ ...advancedFilter, toAddress: e.target.value?.trim() })} />
            </Col>
          )}

          <Col>
            <div className="common-between">
              <Button color="danger" onClick={clearAdvancedFilter} type="button">
                Reset Filter
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AdvanceFilter;
