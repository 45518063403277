import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { topTreasuryChart } from "../../../Data/Dashboard/DashboardChart";

const CreditCardStatistics = ({ staticsData }) => {
  const categories = staticsData?.creditCardPaymentBudget?.map((recipient) => recipient.expense_category);
  const spendData = staticsData?.creditCardPaymentBudget?.map((recipient) => parseFloat(recipient.total_amount));
  const topTreasuryChartOptions = topTreasuryChart(categories, spendData);

  return (
    <Col lg="6">
      <Card className="common-chart">
        <CardHeader className="pb-0">
          <h4>Credit Card Top Recipients</h4>
        </CardHeader>
        <CardBody className="pt-0">
          <ReactApexChart type="line" options={topTreasuryChartOptions} series={topTreasuryChartOptions.series} height={400} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CreditCardStatistics;
