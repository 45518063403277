import React from "react";
import { handleLimitChange } from "./CustomFunctions";

const PageLimitDropdown = ({ limit, setLimit, setPage }) => {
  const paginationOptions = [25, 50, 75, 100];
  return (
    <>
      <span>shows :</span>
      <select value={limit} onChange={(e) => handleLimitChange(e, setLimit, setPage)} type="select" className="form-select w-auto">
        {paginationOptions.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <span>Entries</span>
    </>
  );
};

export default PageLimitDropdown;
