import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { deleteBudgetDataApi } from "../../Api/ApiList";
import useNotifications from "../../Api/useNotifications";
import { useBudget } from "../../Context/BudgetContext";
import CommonPagination from "../Common/CommonPagination";
import DeleteModal from "../Common/DeleteModal";
import PageLimitDropdown from "../Common/PageLimitDropdown";
import BudgetFormsModal from "./BudgetFormsModal/index";
import BudgetTables from "./BudgetTables";
import BudgetTablesDropdown from "./BudgetTablesDropdown";
import ImportModal from "./ImportModal";
import useDebounce from "../../Hooks/useDebounce";
import DeleteAgreementModal from "./DeleteAgreementModal";

const BudgetContainer = () => {
  const { editModal, toggleEdit, type, setParams, deleteModal, toggleImport, setSortColumn, toggleDelete, editBudgetData, refetch, data, setType, isLoading, totalPages, page, setPage, limit, setLimit, searchTerm, setSearchTerm, sortOrder, sortColumn } = useBudget();
  const [editMode, setEditMode] = useState(false);
  const [searchParams] = useSearchParams();
  const tableType = searchParams.get("type") || "bank_payments";
  const { refetchGetData: refetchNotifications } = useNotifications();
  const search = useDebounce(searchTerm?.trim(), 500);

  const addBudget = () => {
    setEditMode(false);
    toggleEdit();
  };

  useEffect(() => {
    setType(tableType);
    setPage(1);
    setLimit(25);
    // setSearchTerm(null);
    setSortColumn("id");
  }, [tableType]);

  useEffect(() => {
    if (type) {
      setParams({ page, limit, type, search, sortOrder: sortOrder ? "asc" : "desc", sortColumn });
    }
  }, [limit, page, search, setParams, sortColumn, sortOrder, type]);

  return (
    <Fragment>
      <section className="common-table">
        <Row className="mb-3 g-3">
          <Col>
            <div className="d-flex align-items-center gap-2">
              <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
              <BudgetTablesDropdown />
            </div>
          </Col>

          <Col className="search-box">
            <div className="common-flex">
              <p className="mb-0">Search :</p>
              <div className="d-flex align-items-center">
                <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <Button color="primary border border-1" onClick={() => toggleImport()}>
              <Plus size={15} /> Import
            </Button>

            <Button color="primary border border-1" onClick={addBudget}>
              <Plus size={15} /> Create Budget
            </Button>
          </Col>
        </Row>
        <div className="custom-scrollbar table-responsive">
          <BudgetTables setEditMode={setEditMode} data={data} isLoadingGetData={isLoading} />
        </div>
        {!isLoading && data?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {deleteModal && <DeleteModal isOpen={deleteModal} toggleModal={toggleDelete} deleteEndpoint={deleteBudgetDataApi} itemData={editBudgetData} itemType="Budget" refetchCallback={refetch} extraFunction={refetchNotifications} />}
      {editModal && <BudgetFormsModal refetchBudget={refetch} isEditMode={editMode} toggleEdit={toggleEdit} type={tableType} />}
      <ImportModal refetchGetData={refetch} />
      <DeleteAgreementModal />
    </Fragment>
  );
};

export default BudgetContainer;
