import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentEthereum = ({ staticsData }) => {
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <h4>Recent Ethereum Transfers</h4>
        </CardHeader>
        <CardBody className="pt-0">
          <div className="table-responsive custom-scroll">
            <Table className="data-transfer-table">
              <thead className="border-bottom">
                <tr>
                  <th>Vendor</th>
                  <th>Wallet Address</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {staticsData?.ethereumWalletBudget?.length > 0 ? (
                  staticsData?.ethereumWalletBudget?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.vendor}</td>
                      <td>{item.wallet_address}</td>
                      <td>{item.amount}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="">
                    <td colSpan="10" className="text-center no-found">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentEthereum;
