// Auth
export const loginApi = "api/auth/login";
export const forgotPasswordApi = "api/auth/forgotpassword";
export const verifyEmailTokenApi = "api/auth/verifyEmail";
export const resetPasswordApi = "api/auth/resetpassword";

// Statistics
export const getStatisticsApi = "/api/getStatics";

// Transaction
export const transactionApi = "api/getTransactions";
export const updateTransactionApi = "api/transation/update";
export const importTransactionApi = "api/import/transaction";

// Accounts
export const updateAccountApi = "api/account/update";
export const importAccountApi = "api/import/account";
export const refreshAccountApi = "api/transaction/refresh";
export const createAccountApi = "api/account/create";
export const deleteAccountApi = "/api/account/delete";
export const getAccountApi = "/api/getAccounts";

// Budget
/* Old */
export const getBudgetApi = "api/getBudgets";
export const createBudgetApi = "api/createBudget";
export const updateBudgetApi = "api/updateBudget";
export const deleteBudgetApi = "/api/deleteBudget";

/* New */
export const importBudgetApi = "api/import/budgetData";
export const getBudgetDataApi = "/api/getBudgetData";
export const createOrUpdateBudgetApi = "api/budget/createOrUpdateBudgetData";
export const deleteBudgetDataApi = "/api/budget/deleteBudgetData";
export const uploadSignedAgreementApi = "api/uploadSignedAgreement";
export const removeAgreementApi = "api/removeSignedAgreement";
export const budgetStatisticsApi = "api/budget/getStatics"
export const budgetChartApi = "api/budget/getBudgetStaticsChart"


// Bridge Transfer
export const getBridgeTransfersApi = "api/getBridgeTransfers";
export const updateBridgeTransferApi = "api/bridgeTrasfer/update";

// Notifications
export const getNotificationsApi = "/api/getAllNotification";
export const readAllNotificationsApi = `/api/markAsReadNotification`;
