import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useNotifications from "../Api/useNotifications";
import { ImagePath } from "../Constants";

const HeaderNotifications = () => {
  const { notificationsData, refetchGetData } = useNotifications();

  useEffect(() => {
    refetchGetData();
  }, []);

  const filteredNotifications = notificationsData?.data?.filter((notification) => !notification?.mark_as_read && notification?.status !== "completed");

  const filteredNotificationData = notificationsData?.data?.filter((notification) => notification?.status !== "completed");

  return (
    <li>
      {filteredNotifications?.length ? <span className="badge badge-danger">{filteredNotifications?.length}</span> : null}
      <div className="light-box">
        <i className="fa-solid fa-bell"></i>
      </div>
      <ul className={`notify-dropdown ${!notificationsData?.data?.length ? "no-notify" : ""}`}>
        <li>
          <h6>Notifications</h6>
        </li>
        {filteredNotificationData?.length ? (
          filteredNotificationData?.slice(0, 4).map((item, i) => (
            <li key={i}>
              <div className="rounded-circle">
                <i className="fa-solid fa-dollar-sign fa-flip"></i>
              </div>
              <div>
                <p className="title">{item?.title}</p>
                <span>{item?.description}</span>
              </div>
            </li>
          ))
        ) : (
          <li>
            <div className="empty-notify">
              <img className="img-fluid" src={`${ImagePath}/no-notify.svg`} alt="no notification" />
              <span>There is no new notifications</span>
            </div>
          </li>
        )}
        {notificationsData?.data?.length ? (
          <li>
            <Link to="/notifications" className="btn btn-primary">
              Check All Notifications
            </Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

export default HeaderNotifications;
