import React from "react";
import { Badge } from "reactstrap";
import { Capitalize, formatDateTime } from "../../../Utils";
import DynamicTable from "../../Common/CommonTable";
import { useBudget } from "../../../Context/BudgetContext";

const BankPaymentsTable = ({ data, isLoadingGetData, setEditMode, handleDelete }) => {
  const { handleSort, sortOrder } = useBudget();
  const columns = [
    {
      key: "to",
      label: "To Address",
      isSortable: true,
    },
    {
      key: "date",
      label: "Invoice Date",
      render: (item) => (item?.date ? formatDateTime(item?.date) : "--"),
      isSortable: true,
    },
    {
      key: "id",
      label: "Invoice No",
      isSortable: true,
    },
    {
      key: "expense_category",
      label: "Expense Category",
    },
    {
      key: "type_of_expense",
      label: "Type of Expense",
    },
    {
      key: "currency",
      label: "Currency",
    },
    {
      key: "amount",
      label: "Amount",
      type: "amount",
      isSortable: true,
    },
    {
      key: "amountUSD",
      label: "Amount (USD)",
      type: "amount",
      isSortable: true,
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      render: (item) => <Badge color={item?.status === "Paid" || item?.status === "Completed" ? "success" : item?.status === "Expired" || item?.status === "Cancelled" ? "danger" : "warning  "}> {Capitalize(item?.status)} </Badge>,
    },
  ];

  return <DynamicTable serialNo handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} data={data?.data || []} columns={columns} isLoading={isLoadingGetData} onEdit={setEditMode} onDelete={handleDelete} />;
};

export default BankPaymentsTable;
