import React from "react";
import { Edit, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Table } from "reactstrap";
import InnerLoader from "../../Layout/InnerLoader";
import { formatAmount, formatDateTime, handleCopyCode, truncateMiddle } from "../../Utils";
import CommonTooltip from "../Common/CommonTooltip";

const DynamicTable = ({ columns, data, serialNo, isLoading, handleSort, sortOrder, onEdit, onDelete, uploadFile, tableClass }) => {
  const renderCellContent = (col, item) => {
    const emptyCellData = "--";
    const cellData = item[col.key] || emptyCellData;
    const redirectLink = col.redirectLinkPrefix ? `${col.redirectLinkPrefix}${cellData}` : null;

    switch (col.type) {
      case "date":
        return cellData === emptyCellData ? emptyCellData : formatDateTime(cellData);

      case "amount":
        return formatAmount(cellData);

      case "boolean":
        return cellData == true || cellData === true ? <i className="fa-solid fa-circle-check treasury-right"></i> : <i className="fa-solid fa-circle-xmark treasury-cancel"></i>;

      case "address": {
        const truncatedData = col?.truncate && (col.useTooltip ? !col.useTooltip(item) : true) ? truncateMiddle(cellData, 12) : cellData;
        return (
          <div className="copy-account">
            {col.useTooltip && col.useTooltip(item) ? (
              <CommonTooltip placement="right" id={`TooltipExample-${col.key}-${item.id}`} content={item[col.tooltipContent]} color={col.tooltipColor || "danger"} redirectLink={redirectLink ? redirectLink : false}>
                {col.useTooltip(item)}
              </CommonTooltip>
            ) : redirectLink ? (
              <Link to={redirectLink} target="_blank">
                {truncatedData}
              </Link>
            ) : (
              truncatedData
            )}
            {cellData !== emptyCellData && <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item[col.tooltipContent] || cellData)} />}
          </div>
        );
      }

      case "link":
        return (
          <Link to={redirectLink} target="_blank">
            {cellData}
          </Link>
        );

      default:
        return col.render ? col.render(item) : cellData;
    }
  };

  return (
    <Table bordered className={tableClass ? tableClass : "transaction-table"}>
      <thead>
        <tr>
          {serialNo && (
            <th className="head-text">
              <p className="header-name">No.</p>
            </th>
          )}
          {columns?.map((col) => (
            <th key={col.key} className="head-text" onClick={col.isSortable ? () => handleSort(col.key) : null}>
              <p className="header-name">
                {col.label}
                {col.isSortable && (sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>)}
              </p>
            </th>
          ))}
          {(onEdit || onDelete || uploadFile) && (
            <th className="head-text">
              <p className="header-name">Action</p>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={columns?.length + (onEdit || onDelete || uploadFile ? 1 : 0)} className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : data?.length > 0 ? (
          data?.map((item, i) => (
            <tr key={item.id}>
              {serialNo && <td>{i + 1}</td>}

              {columns?.map((col) => (
                <td key={col.key}>{renderCellContent(col, item)}</td>
              ))}

              {(onEdit || onDelete || uploadFile) && (
                <td>
                  <div className="common-flex justify-content-center">
                    {onEdit && (
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center" onClick={() => onEdit(item?.id)}>
                          <Edit size={20} />
                        </Button>
                      </div>
                    )}
                    {uploadFile && (
                      <div className="outer-box">
                        <Button color="transparent" onClick={() => uploadFile(item?.id)}>
                          <Upload size={10} />
                        </Button>
                      </div>
                    )}
                    {onDelete && (
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center trash-icon" onClick={() => onDelete(item?.id)}>
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    )}
                  </div>
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns?.length + 1 + (onEdit || onDelete || uploadFile ? 2 : 0)} className="text-center no-found">
              No data found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DynamicTable;
