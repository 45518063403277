import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import { useBudget } from "../../../Context/BudgetContext";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const EthereumWalletPaymentsModal = ({ isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );
  const [loading, setLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const { refetchGetData } = useNotifications();

  useOutsideClick(datePickerRef, () => setDatePickerVisible(false));
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "ethereum_wallet",
      id: null,
      vendor: null,
      invoice_date: null,
      invoice_no: null,
      poc: null,
      expense_category: null,
      expense_type: null,
      currency: null,
      amount: 0,
      amountUSD: 0,
      wallet_address: null,
      confirmation: null,
      status: null,
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    // const apiEndpoint = isEditMode ? updateBudgetApi : createBudgetApi;
    await mutate({ url: createOrUpdateBudgetApi, data: data });
    setLoading(false);
  };

  const handleDateChange = (date) => {
    setValue("invoice_date", date);
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("vendor", editBudgetData.vendor);
      setValue("invoice_date", editBudgetData.invoice_date ? new Date(editBudgetData.invoice_date) : null);
      setValue("invoice_no", editBudgetData.invoice_no);
      setValue("poc", editBudgetData.poc);
      setValue("expense_category", editBudgetData.expense_category);
      setValue("expense_type", editBudgetData.expense_type);
      setValue("currency", editBudgetData.currency);
      setValue("amount", editBudgetData.amount);
      setValue("amountUSD", editBudgetData.amountUSD);
      setValue("wallet_address", editBudgetData.wallet_address);
      setValue("confirmation", editBudgetData.confirmation);
      setValue("status", editBudgetData.status);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Ethereum Wallet Payment" : "Create Ethereum Wallet Payment"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* Vendor */}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Vendor:</label>
                <input {...register("vendor", { required: "Vendor is required" })} className={`form-control ${errors.vendor ? "is-invalid" : ""}`} type="text" placeholder="Enter vendor name" />
                {errors.vendor && <div className="invalid-feedback">{errors.vendor.message}</div>}
              </FormGroup>
            </Col>

            {/* Invoice Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Invoice Date:</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker selected={watch("invoice_date")} className="form-control" placeholderText="Select date" onChange={handleDateChange} open={datePickerVisible} showYearDropdown />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
              </FormGroup>
            </Col>

            {/* Invoice Number */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Invoice Number:</label>
                <input {...register("invoice_no")} className={`form-control ${errors.invoice_no ? "is-invalid" : ""}`} type="text" placeholder="Enter invoice number" />
                {/* {errors.invoice_no && <div className="invalid-feedback">{errors.invoice_no.message}</div>} */}
              </FormGroup>
            </Col>

            {/* POC */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">POC:</label>
                <input {...register("poc")} className={`form-control ${errors.poc ? "is-invalid" : ""}`} type="text" placeholder="Enter POC" />
                {/* {errors.poc && <div className="invalid-feedback">{errors.poc.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Expense Category */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Expense Category:</label>
                <input {...register("expense_category")} className={`form-control ${errors.expense_category ? "is-invalid" : ""}`} type="text" placeholder="Enter expense category" />

                {/* {errors.expense_category && <div className="invalid-feedback">{errors.expense_category.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Currency */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Currency:</label>
                <input {...register("currency", { required: "Currency is required" })} className={`form-control ${errors.currency ? "is-invalid" : ""}`} type="text" placeholder="Enter currency" />
                {errors.currency && <div className="invalid-feedback">{errors.currency.message}</div>}
              </FormGroup>
            </Col>

            {/* Wallet Address */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Wallet Address:</label>
                <input {...register("wallet_address")} className={`form-control ${errors.wallet_address ? "is-invalid" : ""}`} type="text" placeholder="Enter wallet address" />
                {/* {errors.wallet_address && <div className="invalid-feedback">{errors.wallet_address.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Amount */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Amount:</label>
                <input {...register("amount")} className={`form-control ${errors.amount ? "is-invalid" : ""}`} type="number" placeholder="Enter amount" />
                {/* {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Amount USD*/}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Amount (in USD):</label>
                <input {...register("amountUSD")} className={`form-control ${errors.amountUSD ? "is-invalid" : ""}`} type="number" placeholder="Enter amount" />
                {/* {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Confirmation */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Confirmation:</label>
                <input {...register("confirmation")} className={`form-control ${errors.confirmation ? "is-invalid" : ""}`} type="text" placeholder="Enter confirmation" />
                {/* {errors.confirmation && <div className="invalid-feedback">{errors.confirmation.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Expense Type */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Expense Type:</label>
                <select {...register("expense_type")} className={`form-control ${errors.expense_type ? "is-invalid" : ""}`}>
                  <option value="">Select an option</option>
                  <option value="Recurring">Recurring</option>
                  <option value="One-time payment">One-time payment</option>
                </select>
                {/* {errors.expense_type && <div className="invalid-feedback">{errors.expense_type.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Status */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EthereumWalletPaymentsModal;
