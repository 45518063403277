import React from "react";
import { Badge } from "reactstrap";
import { Capitalize } from "../../../Utils";
import DynamicTable from "../../Common/CommonTable";
import { useBudget } from "../../../Context/BudgetContext";

const AvailWalletPayments = ({ data, isLoadingGetData, setEditMode, handleDelete }) => {
  const {handleSort,sortOrder} = useBudget()
  const columns = [
    { label: "Vendor", key: "vendor" },
    { label: "POC", key: "poc" },
    { label: "Expense Category", key: "expense_category" },
    { label: "Type of Expense", key: "expense_type" },
    { label: "Currency", key: "currency" },
    { label: "TWAP Price", key: "twap_price", type: "amount",isSortable: true },
    { label: "Amount in USD", key: "amountUSD", type: "amount" ,isSortable: true},
    { label: "Amount in Avail", key: "amountAvail" ,isSortable: true},
    { label: "Wallet Address", key: "wallet_address", type: "address", truncate: true },
    { label: "Confirmation (TXN)", key: "confirmation", type: "address", truncate: true },
    { label: "Status", key: "status",isSortable: true ,render: (item) => <Badge color={item?.status === "Live" || item?.status === "Completed" || item?.status === "Paid" ? "success" : item?.status === "Expired" ? "danger" : "warning  "}> {Capitalize(item?.status)} </Badge> },
  ];

  return <DynamicTable  serialNo handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} columns={columns} data={data?.data || []} isLoading={isLoadingGetData} onDelete={handleDelete} onEdit={setEditMode} />;
};

export default AvailWalletPayments;
