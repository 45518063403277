import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Tooltip } from "reactstrap";
import { refreshAccountApi, transactionApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useTransaction } from "../../Context/TransactionContext";
import useDebounce from "../../Hooks/useDebounce";
import useDebouncedObject from "../../Hooks/useDebounceObject";
import { toMilliseconds } from "../../Utils";
import AdvanceFilter from "../Common/AdvanceFilter";
import CommonPagination from "../Common/CommonPagination";
import { calculateRemainingTime, handleRefresh } from "../Common/CustomFunctions";
import PageLimitDropdown from "../Common/PageLimitDropdown";
import EditModal from "./EditModal";
import TransactionDataTable from "./TransactionDataTable";

const TransactionContainer = () => {
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("timestamp");
  const [showFilter, setShowFilter] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [remainingTime, setRemainingTime] = useState();

  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    fromAddress: null,
    toAddress: null,
  });

  const debouncedObject = useDebouncedObject(advancedFilter, 500);
  const { startDate = null, endDate = null, minAmount = null, maxAmount = null, fromAddress = null, toAddress = null } = debouncedObject;

  const { editModal, searchTerm, setSearchTerm } = useTransaction();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: transactionData, refetch: refetchGetData } = useGetApi({ url: `/${transactionApi}`, params, options: { enabled: !!Object.keys(params).length } });
  const { data: refreshedData, refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: false } });

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // send advance filter params in api
  useEffect(() => {
    // Check if `from_address` and `to_address` are not empty
    if (fromAddress?.trim() !== "" && toAddress?.trim() !== "") {
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: fromAddress,
        to_address: toAddress,
      });
    } else {
      // Set params without address fields if they are empty
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: null, // or null, depending on how your API handles these values
        to_address: null, // or null
      });
    }
  }, [startDate, endDate, fromAddress, toAddress, minAmount, maxAmount]);

  // total pages of data
  useEffect(() => {
    if (transactionData) {
      setTotalPages(transactionData.totalPages);
    }
  }, [transactionData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      sortOrder: sortOrder ? "desc" : "asc",
      sortColumn: sortColumn,
    }));
  }, [search, page, limit, sortOrder, sortColumn]);

  useEffect(() => {
    if (search.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, sortColumn, isLoadingGetData, sortOrder, refetchGetData]);

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem("lastRefreshTime");
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  return (
    <Fragment>
      <section className="common-table">
        <Row className="mb-3 g-3">
          <Col>
            <div className="d-flex align-items-center gap-2">
              <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
            </div>
          </Col>
          <Col className="search-box">
            <div className="common-flex">
              <p className="mb-0">Search :</p>
              <div className="d-flex align-items-center">
                <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <div className="light-box" onClick={() => setShowFilter(!showFilter)}>
              {showFilter ? <i className="fa-solid fa-xmark" /> : <i className="fa-solid fa-filter" />}
            </div>
            <span id="refresh-tooltip" style={{ cursor: "pointer" }}>
              <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
                <div className="light-box" onClick={() => handleRefresh(refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime)}>
                  <i className="fa-solid fa-rotate"></i>
                </div>
              </Button>
            </span>
            <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
              <p>
                {tooltipContent} <b>{remainingTime}</b>{" "}
              </p>
            </Tooltip>
          </Col>
        </Row>
        {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} /> : null}
        <div className="custom-scrollbar table-responsive">
          <TransactionDataTable handleSort={handleSort} isLoadingGetData={isLoadingGetData} sortOrder={sortOrder} transactionData={transactionData} />
        </div>
        {!isLoadingGetData && transactionData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {editModal && <EditModal refetchTransaction={refetchGetData} />}
    </Fragment>
  );
};

export default TransactionContainer;
